<template>
  <button
    @click="chooseRentPeriod"
    :class="{ 'btn-primary--outline': !rentPeriod.selected, 'btn-primary': rentPeriod.selected }"
    class="btn"
    type="button"
  >
    {{ rentPeriod.name }}
  </button>
</template>

<script>
export default {
  props: ['rentPeriod'],
  methods: {
    chooseRentPeriod() {
      this.$store.commit('v2/spaceManagerRequest/SET_SELECTED_RENT_PERIOD', this.rentPeriod.id);
    },
  },
};
</script>
